var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),(!_vm.isMobile)?_c('div',{staticClass:"is-flex mt-5 is-justify-content-center"},[_vm._m(1),_c('div',[_c('Icon',{staticClass:"pr-4 pt-2",attrs:{"data-tooltip":"invalidado","file":"timeline_documents","size":"70:400"}})],1),_vm._m(2)]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"is-flex is-flex-direction-row is-justify-content-center is-align-items-center mt-5"},[_c('Icon',{staticClass:"pr-4",attrs:{"data-tooltip":"invalidado","file":"timeline_amp","size":"30"}}),_c('p',[_vm._v(" Enquanto estivermos analisando seu documento, você visualizará o seu status pela aba “Em análise”. ")])],1),_c('div',{staticClass:"is-flex is-flex-direction-row is-justify-content-center is-align-items-center mt-5"},[_c('Icon',{staticClass:"pr-4",attrs:{"data-tooltip":"invalidado","file":"timeline_error","size":"30"}}),_c('p',[_vm._v(" Caso seu documento não esteja nos padrões solicitados, moveremos para a aba “Invalidados”. ")])],1),_c('div',{staticClass:"is-flex is-flex-direction-row is-justify-content-center is-align-items-center mt-5 mb-5"},[_c('Icon',{staticClass:"pr-4",attrs:{"data-tooltip":"invalidado","file":"timeline_success","size":"30"}}),_c('p',[_vm._v(" Caso seu documento seja aceito, moveremos para a aba “Validados”. ")])],1)]):_vm._e(),_c('div',{staticClass:"columns is-flex mt-5 mb-5",class:{
			'is-justify-content-flex-end': !_vm.isMobile,
			'is-justify-content-center': _vm.isMobile,
		}},[_c('div',{staticClass:"column is-5-desktop is-5-tablet is-3-widescreen is-12-mobile",class:{'has-text-centered': _vm.isMobile}},[_c('button',{staticClass:"button is-primary is-rounded",on:{"click":_vm.handleCloseModal}},[_vm._v(" Concluir ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"color-grey is-size-3 has-text-bold has-text-centered mb-4"},[_vm._v(" Documento Enviado ")]),_c('p',{staticClass:"color-grey has-text-centered is-size-5"},[_vm._v(" Seu documento será analisado. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex is-align-items-center is-justify-content-flex-end"},[_c('p',{staticClass:"text-invalidados"},[_vm._v(" Caso seu documento não esteja nos padrões solicitados, moveremos para a aba “Invalidados”. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-space-between"},[_c('p',{staticClass:"text-em-analise"},[_vm._v(" Enquanto estivermos analisando seu documento, você visualizará o seu status pela aba “Em análise”. ")]),_c('p',{staticClass:"text-validados"},[_vm._v(" Caso seu documento seja aceito, moveremos para a aba “Validados”. ")])])
}]

export { render, staticRenderFns }