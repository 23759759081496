var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"is-flex is-justify-content-center is-align-items-center",class:{
			'is-flex-direction-column-reverse': _vm.isMobile
		}},[_c('Icon',{staticClass:"tabs-custom-icon pr-4 pt-2",attrs:{"data-tooltip":"invalidado","file":"warning","size":"28"}}),_c('h1',{staticClass:"has-text-centered color-grey is-size-3 is-flex is-justify-content-center"},[_vm._v(" Cancelar envio ")])],1),_c('p',{staticClass:"color-grey has-text-centered is-size-5 mt-5"},[_vm._v(" Você deseja desconsiderar o envio deste documento? ")]),_c('p',{staticClass:"color-grey has-text-centered is-size-5 mt-5 mb-5"},[_vm._v(" Uma vez cancelado, este documento será invalidado para análise. ")])]),_c('div',{staticClass:"is-flex",class:{
			'is-justify-content-flex-end': !_vm.isMobile,
			'is-justify-content-flex-center is-flex-direction-column': _vm.isMobile,
		}},[_c('div',{staticClass:"column is-4-tablet is-3-desktop is-2-fullhd is-12-mobile",class:{
				'is-flex is-justify-content-flex-end': !_vm.isMobile,
				'has-text-centered': _vm.isMobile
			}},[_c('button',{staticClass:"button is-primary is-rounded is-outlined",on:{"click":_vm.handleBack}},[_vm._v(" Voltar ")])]),_c('div',{staticClass:"column is-4-desktop is-6-tablet is-3-fullhd is-12-mobile is-4-widrescreen",class:{
				'is-flex is-justify-content-flex-end': !_vm.isMobile,
				'has-text-centered': _vm.isMobile
			}},[_c('button',{staticClass:"button is-primary is-rounded",class:{'px-4': _vm.isMobile},on:{"click":_vm.handleCancel}},[_vm._v(" Sim, quero cancelar o envio ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }